<template>
    <card class="wrapper">
        <AdminTitle title="账期" size="20px">
            <span class="tip"
                ><i class="el-icon-warning"></i>套现空间：￥{{ detailInfo.arbitragePrice }}</span
            >
        </AdminTitle>
        <card class="wrapper special-card">
            <div class="item" v-for="(item, i) in list" :key="i">
                <el-radio v-model="storeBillIndex" :label="i + ''" @click.self="changeType(i)">{{
                    item.optionName
                }}</el-radio>
                <div class="col2">
                    <el-checkbox
                        disabled
                        v-for="(bill, index) in item.billList"
                        :key="index"
                        v-model="bill.isPrepay"
                    >
                        <span>第{{ bill.billNum }}期（￥：{{ bill.billPrice }}）</span>
                        <span>
                            <el-button
                                :disabled="storeBillIndex != i"
                                style="margin-left: 5px"
                                v-if="bill.billNum == 1 && bill.isPrepay"
                                type="primary"
                                size="mini"
                                @click="updatePrice(item, bill.billPrice)"
                                >修改</el-button
                            >
                        </span>
                    </el-checkbox>
                </div>
                <div class="first-price">
                    首付金额：<span>{{ item.prepayPrice }}元</span>
                </div>
            </div>
            <el-checkbox-group
                v-model="periodList"
                @change="changeSelect"
                v-if="!list.length || !list"
            >
                <el-checkbox v-for="item in billPeriodNum" :key="item" :label="item"
                    >第{{ item }}期（￥：{{ eachRentPrice }}）</el-checkbox
                >
            </el-checkbox-group>
        </card>
        <el-dialog title="第一期金额修改" :visible.sync="dialogVisible" width="400px">
            <el-form ref="form" :model="formData" label-width="120px">
                <el-form-item label="新第一期金额">
                    <el-input-number
                        style="width: 200px"
                        v-model="formData.fixFirstBillPrice"
                        size="small"
                        :precision="2"
                        :min="0"
                    ></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="savePrice" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </card>
</template>

<script>
import { updateStoreFirstPriceAPI } from './api';

export default {
    props: {
        detailInfo: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        list() {
            return this.detailInfo.storeBillOptionList || [];
        },
        billPeriodNum() {
            return this.detailInfo.billPeriodNum;
        },
        eachRentPrice() {
            return this.detailInfo.eachRentPrice;
        },
    },
    created() {
        localStorage.setItem('storeBillIndex:' + this.formData.orderNo, this.storeBillIndex);
    },
    data() {
        return {
            periodList: [],
            dialogVisible: false,
            storeBillIndex: '0',
            formData: {
                orderNo: this.$route.params.id,
                storeComboOption: '',
                fixFirstBillPrice: '',
            },
        };
    },
    methods: {
        changeSelect() {
            this.$store.commit('UPDATE_PERIOD', this.periodList);
        },
        updatePrice({ optionType }, billPrice) {
            this.formData.storeComboOption = optionType;
            this.formData.fixFirstBillPrice = billPrice;
            this.dialogVisible = true;
        },
        async savePrice() {
            const res = await updateStoreFirstPriceAPI(this.formData);
            this.$emit('resetBill', { bill: res, index: this.storeBillIndex });
            this.$message.success('修改成功');
            this.dialogVisible = false;
        },
        changeType(i) {
            const origin = JSON.parse(localStorage.getItem('storeBill:' + this.formData.orderNo));
            const origin_bill = origin[Number(this.storeBillIndex)];
            this.$confirm('修改方案系统将会重置账单金额', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$emit('resetBill', {
                        bill: origin_bill,
                        index: this.storeBillIndex,
                    });
                    localStorage.setItem('storeBillIndex:' + this.formData.orderNo, i);
                    this.storeBillIndex = i + '';
                    this.$store.commit(
                        'UPDATE_PERIOD',
                        this.list[Number(this.storeBillIndex)].prepayBill
                    );
                    this.$message({
                        type: 'success',
                        message: '修改成功!',
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消修改',
                    });
                });
        },
    },
    beforeDestroy() {
        localStorage.removeItem('updateBill:' + this.$route.params.id);
        localStorage.removeItem('storeBillIndex:' + this.$route.params.id);
        localStorage.removeItem('storeBill:' + this.$route.params.id);
    },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
    width: 100%;
    border-color: transparent;
    margin-top: 20px;
    border-radius: 16px;
    color: #222222;
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
        color: #222222;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        border-color: #222;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #222;
    }
    .el-checkbox__input.is-disabled .el-checkbox__inner {
        border-color: #222;
    }
    .first-price {
        font-weight: 600;
    }
    .col2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .el-checkbox {
            min-width: 250px;
        }
    }
    .el-button {
        border-radius: 15px;
    }
}
.tip {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 20px;
    background: #bd3124;
    position: absolute;
    left: 60px;
    top: 2px;
    font-weight: 550;
    .el-icon-warning {
        color: #fff;
        margin-right: 5px;
    }
}
.special-card {
    background: #f7f8fa;
    line-height: 35px;
    color: #222222;
}
</style>
